/* global VXConfig */
import React                    from "react";
import { getCountdownTillDate } from "../Helper/CountdownHelper";
import style                    from './FifteenMinutesPreviewCountdown.module.less';
import Translations             from "../../utils/Translations";

const isFifteenMinutesPreviewActive = () => {
    if (VXConfig.guest?.esFreePreview?.timerEnd) {
        const endTime = VXConfig.guest.esFreePreview.timerEnd;
        const end     = new Date(endTime * 1000);
        const {isDone} = getCountdownTillDate(end);
        return !isDone;
    }
    return false;
};

const fifteenMinutesPreviewBadge = ({isMobileMenu = false, isMobileSubMenu = false}) => {
    return (isFifteenMinutesPreviewActive() || VXConfig.isGuestEligibleForSpecialFreePreview) && <div className={`${style.badge} ${isMobileMenu && style.badgeMobileMenu} ${isMobileSubMenu && style.badgeMobileSubMenu}`}><img src="/assets/img/modal/fifteen-minutes/clock.png" />{Translations.get('fifteen-minutes-preview.badge')}</div>;
};


export {
    isFifteenMinutesPreviewActive,
    fifteenMinutesPreviewBadge
};
